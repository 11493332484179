<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="mb-4 mb-lg-6">
        <h3 class="font-size-h1 font-weight-normal">이메일 인증</h3>
        <p class="font-weight-semi-bold font-size-lg mt-10 mb-10 pt-10 pb-10">
          {{confirmed ? '인증이 완료되었습니다.':'이미 인증이 완료되었거나, 실패하였습니다. 다시 시도해주세요.'}}
        </p>
      </div>
      <div class="form">
        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
        <router-link
            class="ml-2"
            :to="{ name: 'signin' }"
        >
        <b-button
            block
            variant="primary"
            ref="signinBtn"
            class="px-9 py-4 my-3"
          >
            <span class="font-weight-bold font-size-h5">로그인 하러가기</span>
          </b-button>
        </router-link>
        </div>
        <!--end::Action-->
      </div>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {UPDATE_CONFIRM_SIGNUP} from '@/core/services/store/auth.module';
import {isSuccess} from '@/core/services/funcs';

export default {
  name: "EmailConfirm",
  beforeMount(){
    Object.keys(this.$route.query).forEach(key =>{
        if(this.$route.query[key].length < 50){
          this.emailConfirmInfo[key] = this.$route.query[key]
        }
    })
    this.$store.dispatch(UPDATE_CONFIRM_SIGNUP, {email: this.emailConfirmInfo.email, confirmToken: this.emailConfirmInfo.confirmToken, confirmType: this.emailConfirmInfo.confirmType}).then(resp=>{
        if(isSuccess(resp)){
            this.confirmed = true
        }
    })
    },
  watch: {
  },
  data() {
    return {
        emailConfirmInfo : {
            email: '',
            confirmToken: '',
            confirmType: 0
        },
        confirmed: false
    }
  }

}
</script>
